$bg-main: #fff;
$bg-btn: #eb008b;
$bg-btn-hover: #00adee;
$bg-dark: #252525;

$color-main: #4c4c4c;
$color-link: #00adee;
$color-link-hover: #0f7e00;
$color-yellow: #d0cc7f;
$color-pink: #fe008f;

$font-main: 18px/1.33 'cabinsemibold', Arial, Helvetica, sans-serif;
$font-cabin: 'cabinsemibold', Arial, Helvetica, sans-serif;
$font-cabin-italic: 'cabinsemibold_italic', Arial, Helvetica, sans-serif;
$font-cabin-bold: 'cabinbold', Arial, Helvetica, sans-serif;
$font-cabin-regular: 'cabinregular', Arial, Helvetica, sans-serif;
$font-cabin-reg_italic: 'cabinitalic', Arial, Helvetica, sans-serif;
$font-cabin-bold_italic: 'cabinbold_italic', Arial, Helvetica, sans-serif;
$font-biko: 'bikoregular', Arial, Helvetica, sans-serif;
$font-jenna: 'jenna_sueregular', Arial, Helvetica, sans-serif;
$font-open_sans: 'open_sansregular', Arial, Helvetica, sans-serif;
$font-open_sans-italic: 'open_sansitalic', Arial, Helvetica, sans-serif;
$font-open_sans-bold: 'open_sansbold', Arial, Helvetica, sans-serif;

$border-color: #000;

$desktop: 1562px;
$tablet: 1024px;
$mobile: 767px;
$mobile-small: 480px;