.footer {
	background: $bg-dark;
	color: #fff;
	padding: 44px 0;
	@include media(mobile) {
		text-align: center;
		padding: 20px 0;
	}
	p {
		margin: 0;
	}
	a {
		color: #fff;
	}
	.logo {
		float: left;
		@include media(mobile) {
			float: none;
			display: inline-block;
			vertical-align: top;
		}
	}
}
.footer-menu.nav {
	font-size: 0;
	font-family: $font-biko;
	margin: 45px 0 0;
	float: right;
	@include media(mobile) {
		float: none;
		margin-top: 15px;
	}
	li {
		padding: 0;
		margin: 0 0 0 70px;
		font-size: 24px;
		display: inline-block;
		vertical-align: top;
		&:before {
			display: none;
		}
		@include media(tablet) {
			margin-left: 33px;
			font-size: 18px;
		}
		@include media(mobile) {
			margin: 0 5px;
			font-size: 14px;
		}
		&.dropdown{
			display: none;
		}
	}
	a {
		display: inline-block;
		vertical-align: top;
		padding: 0;
		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
			color: $color-link;
			background: none;
		}
	}
	.separator {
		font-size: 0;
		&:before {
			content: '/';
			display: inline-block;
			vertical-align: top;
			font-size: 24px;
			margin: 0 6px;
			@include media(tablet) {
				font-size: 18px;
			}
			@include media(mobile) {
				font-size: 14px;
				margin: 0 3px;
			}
		}
	}
}
.logo {
	svg {
		@include media(mobile) {
			width: 160px;
			height: 74px;
		}
	}
	a {
		display: block;
		transition: opacity .35s;
		&:hover {
			opacity: .6;
		}
	}
}