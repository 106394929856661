/* Global reset
*******************************************************************************/
html, body, div, a, object, iframe, p, blockquote, pre,
img, dl, dt, dd, ol, ul, li, h1, h2, h3, h4, h5, h6,
fieldset, form, legend, textarea, button, input, select, figure {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box; }

/* =HTML5 display definitions
*******************************************************************************/
header,
footer,
article,
section,
nav,
figure,
aside,
main {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

mark {
  background: none; }

[hidden] {
  display: none; }

svg:not(:root) {
  overflow: hidden; }

/* Tags reset
*******************************************************************************/
blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

ul,
ol {
  margin: 0 0 15px;
  list-style-position: inside; }

ul ul,
ul ol,
ol ul,
ol ol {
  padding: 0 0 0 15px; }

a:hover, a:active, a:focus,
label:hover,
label:active,
label:focus {
  outline: none; }

img {
  border: 0;
  vertical-align: top;
  display: inline-block;
  max-width: 100%;
  height: auto; }

/* Normalizing form elements
*******************************************************************************/
form,
fieldset {
  border: none; }

textarea {
  overflow: auto;
  min-height: 30px;
  max-height: 400px;
  resize: vertical;
  -webkit-border-radius: 0;
  -webkit-appearance: none; }

input[type="checkbox"], input[type="radio"] {
  padding: 0;
  vertical-align: middle; }

button {
  cursor: pointer; }
  button[disabled] {
    cursor: default; }

button::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
  border: 0;
  padding: 0; }

input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer; }

input[disabled] {
  cursor: default; }

input[type="image"] {
  padding: 0;
  border: none; }

input[type="submit"], input[type="button"], input[type="text"], input[type="password"], input[type="file"], input[type="search"], input[type="email"], input[type="tel"] {
  -webkit-appearance: none; }

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration {
  display: none; }

input::-ms-clear {
  display: none;
  width: 0;
  height: 0; }

input::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: none; }
  input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="submit"],
input[type="text"] {
  -webkit-border-radius: 0; }

/* Normalizing Tags
*******************************************************************************/
small {
  font-size: 85%; }

strong,
th {
  font-weight: bold; }

td,
td img {
  vertical-align: top; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 18px; }

p {
  margin: 0 0 18px; }

/* =Minimal base appearance
*******************************************************************************/
html {
  height: 100%; }

input,
textarea,
select {
  font: 100% 18px/1.33 "cabinsemibold", Arial, Helvetica, sans-serif;
  vertical-align: middle; }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #4c4c4c; }

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #4c4c4c; }

input:-moz-placeholder, input::-moz-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder {
  color: #4c4c4c;
  opacity: 1; }

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent; }

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent; }

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: transparent; }

/* =Useful classes
*******************************************************************************/
.clearfix:before,
.clearfix:after {
  content: '';
  display: table; }

.clearfix:after {
  clear: both; }

.alignleft,
.alignright,
.aligncenter {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  height: auto; }

.alignleft,
.alignright,
.aligncenter {
  float: left;
  margin: 0 15px 15px 0; }
  @media only screen and (max-width: 1024px) {
    .alignleft,
    .alignright,
    .aligncenter {
      float: none;
      display: block;
      margin: 0 auto 15px; } }

.alignright {
  float: right;
  margin: 0 0 15px 15px; }

.aligncenter {
  float: none;
  display: block;
  margin: 0 auto 15px;
  text-align: center; }

/* End reset
*******************************************************************************/
@font-face {
  font-family: 'bikoregular';
  src: url("../../fonts/biko_regular-webfont.woff2") format("woff2"), url("../../fonts/biko_regular-webfont.woff") format("woff"); }

@font-face {
  font-family: 'jenna_sueregular';
  src: url("../../fonts/jennasue-webfont.woff2") format("woff2"), url("../../fonts/jennasue-webfont.woff") format("woff"); }

@font-face {
  font-family: 'cabinregular';
  src: url("../../fonts/cabin-regular-webfont.woff2") format("woff2"), url("../../fonts/cabin-regular-webfont.woff") format("woff"); }

@font-face {
  font-family: 'cabinbold';
  src: url("../../fonts/cabin-bold-webfont.woff2") format("woff2"), url("../../fonts/cabin-bold-webfont.woff") format("woff"); }

@font-face {
  font-family: 'cabinsemibold';
  src: url("../../fonts/cabin-semibold-webfont.woff2") format("woff2"), url("../../fonts/cabin-semibold-webfont.woff") format("woff"); }

@font-face {
  font-family: 'cabinsemibold_italic';
  src: url("../../fonts/cabin-semibolditalic-webfont.woff2") format("woff2"), url("../../fonts/cabin-semibolditalic-webfont.woff") format("woff"); }

@font-face {
  font-family: 'cabinbold_italic';
  src: url("../../fonts/cabin-bolditalic-webfont.woff2") format("woff2"), url("../../fonts/cabin-bolditalic-webfont.woff") format("woff"); }

@font-face {
  font-family: 'cabinitalic';
  src: url("../../fonts/cabin-italic-webfont.woff2") format("woff2"), url("../../fonts/cabin-italic-webfont.woff") format("woff"); }

@font-face {
  font-family: 'open_sansbold';
  src: url("../../fonts/opensans-bold-webfont.woff2") format("woff2"), url("../../fonts/opensans-bold-webfont.woff") format("woff"); }

@font-face {
  font-family: 'open_sansitalic';
  src: url("../../fonts/opensans-italic-webfont.woff2") format("woff2"), url("../../fonts/opensans-italic-webfont.woff") format("woff"); }

@font-face {
  font-family: 'open_sansregular';
  src: url("../../fonts/opensans-regular-webfont.woff2") format("woff2"), url("../../fonts/opensans-regular-webfont.woff") format("woff"); }

* {
  font-size: 100.01%; }

html,
body {
  height: 100%; }

html {
  font-size: 62.5%; }

body {
  margin: 0;
  background: #fff;
  color: #4c4c4c;
  font: 18px/1.33 "cabinsemibold", Arial, Helvetica, sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  min-width: 320px; }

a {
  color: #00adee;
  text-decoration: none;
  transition: color .3s ease-out, background .3s ease-out, border-color .3s ease-out, opacity .3s ease-out; }

a:hover {
  color: #0f7e00; }

button,
input[type="submit"] {
  transition: color .3s ease-out, background .3s ease-out, border-color .3s ease-out, opacity .3s ease-out; }

h1 {
  margin: 0 0 15px;
  font-size: 48px;
  font-family: "bikoregular", Arial, Helvetica, sans-serif; }

h2 {
  font-size: 60px;
  font-family: "jenna_sueregular", Arial, Helvetica, sans-serif; }

h3 {
  font-size: 24px;
  font-family: "cabinbold", Arial, Helvetica, sans-serif; }

p {
  margin: 0 0 24px; }

strong,
b {
  font-family: "cabinbold", Arial, Helvetica, sans-serif;
  font-weight: normal; }

em,
i {
  font-family: "cabinsemibold_italic", Arial, Helvetica, sans-serif;
  font-style: normal; }

strong em,
b em,
strong i,
b i,
em b,
em strong,
i b,
i strong {
  font-weight: normal;
  font-style: normal;
  font-family: "cabinbold_italic", Arial, Helvetica, sans-serif; }

ul {
  list-style: none; }
  ul li {
    padding: 0 0 0 20px;
    position: relative; }
    ul li:before {
      content: '';
      position: absolute;
      left: 0;
      top: 7px;
      background: #00adee;
      width: 10px;
      height: 10px;
      border-radius: 50%; }

.wrapper {
  display: table;
  height: 100%;
  width: 100%;
  table-layout: fixed;
  position: relative;
  background: #fff; }
  @media only screen and (max-width: 767px) {
    .wrapper {
      display: block; } }

.container {
  width: 100%;
  max-width: 1562px;
  padding: 0 15px;
  margin: 0 auto; }

/* main */
.main {
  display: table-row;
  height: 100%;
  position: relative;
  z-index: 1;
  background: #fff; }
  @media only screen and (max-width: 767px) {
    .main {
      display: block;
      height: auto; } }

.pending {
  color: #060; }

.in-progress {
  color: #f60; }

.failed {
  color: #f00; }

.done {
  color: #096; }

.card a {
  display: inline-block;
  border: 2px transparent solid; }

.card a.selected {
  border-color: #5cb85c; }

.btns {
  height: 60px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  background: #eb008b;
  min-width: 290px;
  font-size: 24px;
  line-height: 58px;
  border-color: #eb008b;
  font-family: "open_sansregular", Arial, Helvetica, sans-serif;
  display: inline-block;
  vertical-align: top;
  text-transform: capitalize;
  padding: 0 20px; }
  @media only screen and (max-width: 1024px) {
    .btns {
      min-width: 0;
      font-size: 18px;
      height: 48px;
      line-height: 46px; } }
  .btns:hover, .btns:focus, .btns:active {
    background: #00adee;
    border-color: #00adee;
    color: #fff;
    text-decoration: none; }
  .btns.with-shadow {
    box-shadow: 0 9px 24px rgba(0, 0, 0, 0.33); }

.list-view {
  font-size: 0; }

.list-view .card {
  display: inline-block;
  vertical-align: middle;
  padding: 0 10px;
  float: none; }
  @media only screen and (max-width: 767px) {
    .list-view .card {
      width: 50%; } }
  @media only screen and (max-width: 480px) {
    .list-view .card {
      width: 100%;
      text-align: center; } }

.message-holder {
  margin: 0 0 50px; }
  @media only screen and (max-width: 767px) {
    .message-holder {
      margin-bottom: 30px; } }

.note-box {
  color: red;
  display: inline-block;
  vertical-align: top;
  padding: 3px 0 0;
  color: #a94442; }
  .note-box p {
    margin: 0; }
  .note-box .required {
    color: red; }

.not-set {
  color: #c55;
  font-style: italic; }

/* add sorting icons to gridview sort links */
a.asc:after, a.desc:after {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  padding-left: 5px; }

a.asc:after {
  content: "\e151"; }

a.desc:after {
  content: "\e152"; }

.sort-numerical a.asc:after {
  content: "\e153"; }

.sort-numerical a.desc:after {
  content: "\e154"; }

.sort-ordinal a.asc:after {
  content: "\e155"; }

.sort-ordinal a.desc:after {
  content: "\e156"; }

.grid-view th {
  white-space: nowrap; }

.hint-block {
  display: block;
  margin-top: 5px;
  color: #999; }

.error-summary {
  color: #a94442;
  background: #fdf7f7;
  border-left: 3px solid #eed3d7;
  padding: 10px 20px;
  margin: 0 0 15px 0; }

form div.required label.control-label:after {
  content: " * ";
  color: red; }

.navbar-inverse {
  background: #fff;
  border: 0;
  padding: 12px 0; }
  @media only screen and (max-width: 767px) {
    .navbar-inverse {
      padding: 5px 0; } }
  .navbar-inverse .navbar-nav {
    font-family: "bikoregular", Arial, Helvetica, sans-serif;
    font-size: 18px;
    margin: 30px 0 0;
    transition: opacity .2s;
    color: #000; }
    @media only screen and (max-width: 767px) {
      .navbar-inverse .navbar-nav {
        margin: 0;
        padding: 15px;
        color: #fff;
        font-size: 17px; } }
    .navbar-inverse .navbar-nav li {
      padding: 0;
      margin: 0 0 0 33px; }
      @media only screen and (max-width: 767px) {
        .navbar-inverse .navbar-nav li {
          margin: 0 0 10px; } }
      @media only screen and (max-width: 767px) {
        .navbar-inverse .navbar-nav li:last-child {
          margin-bottom: 0; } }
      .navbar-inverse .navbar-nav li:last-child.dropdown span {
        vertical-align: middle; }
      @media only screen and (max-width: 767px) {
        .navbar-inverse .navbar-nav li:last-child.dropdown > a {
          display: none; } }
      .navbar-inverse .navbar-nav li:last-child.dropdown .dropdown-menu {
        padding: 20px; }
        @media only screen and (max-width: 767px) {
          .navbar-inverse .navbar-nav li:last-child.dropdown .dropdown-menu {
            display: block;
            position: static;
            background: transparent;
            border: none;
            padding: 0; } }
        .navbar-inverse .navbar-nav li:last-child.dropdown .dropdown-menu li {
          margin: 0 0 10px 0; }
          .navbar-inverse .navbar-nav li:last-child.dropdown .dropdown-menu li.dropdown-header {
            font-size: 14px; }
          @media only screen and (max-width: 767px) {
            .navbar-inverse .navbar-nav li:last-child.dropdown .dropdown-menu li {
              font-size: 17px; } }
          .navbar-inverse .navbar-nav li:last-child.dropdown .dropdown-menu li:last-child {
            margin: 0; }
          .navbar-inverse .navbar-nav li:last-child.dropdown .dropdown-menu li a:hover {
            text-decoration: underline; }
      .navbar-inverse .navbar-nav li:last-child.dropdown.open a {
        background: transparent;
        color: #000; }
      .navbar-inverse .navbar-nav li:before {
        display: none; }
      .navbar-inverse .navbar-nav li.active a {
        color: #eb008b;
        background: none; }
      .navbar-inverse .navbar-nav li a {
        color: #000;
        padding: 0;
        display: inline-block;
        vertical-align: top; }
        @media only screen and (max-width: 767px) {
          .navbar-inverse .navbar-nav li a {
            color: #fff;
            display: inline-block;
            vertical-align: top; } }
        .navbar-inverse .navbar-nav li a:hover, .navbar-inverse .navbar-nav li a:focus, .navbar-inverse .navbar-nav li a:active {
          color: #eb008b;
          background: none; }
    .navbar-inverse .navbar-nav .separator {
      margin: 0 3px; }
  .navbar-inverse .navbar-toggle {
    background: #252525;
    margin: 20px 0 0;
    cursor: pointer;
    transition: background .35s, border-color .35s; }
    @media only screen and (max-width: 767px) {
      .navbar-inverse .navbar-toggle {
        margin-top: 15px; } }
    .navbar-inverse .navbar-toggle:hover {
      background: #eb008b;
      border-color: #eb008b; }
  @media only screen and (max-width: 767px) {
    .navbar-inverse .navbar-collapse {
      background: #252525;
      max-height: none;
      margin: 0;
      overflow: visible;
      position: absolute;
      left: 0;
      right: 0;
      top: 100%; } }
  @media only screen and (max-width: 767px) {
    .navbar-inverse .navbar-collapse .navbar-nav {
      opacity: 0; } }
  .navbar-inverse .navbar-collapse.in .navbar-nav {
    opacity: 1; }
  .navbar-inverse .navbar-header {
    margin: 0; }

.header .navbar-inverse .navbar-brand {
  background: url("../../images/logo.svg") no-repeat;
  font-size: 0;
  line-height: 0;
  padding: 0;
  width: 215px;
  height: 78px;
  margin: 0;
  transition: opacity .35s; }
  .header .navbar-inverse .navbar-brand:hover {
    opacity: 0.6; }
  @media only screen and (max-width: 767px) {
    .header .navbar-inverse .navbar-brand {
      width: 180px;
      height: 65px;
      background-size: 180px 65px; } }

.modal-open .header .navbar-fixed-top {
  padding-right: 17px; }
  @media only screen and (max-width: 767px) {
    .modal-open .header .navbar-fixed-top {
      padding-right: 0; } }

body.iosBugFixCaret.modal-open {
  position: fixed;
  width: 100%; }

.nav li > form > button.logout {
  padding: 15px;
  border: none; }
  @media only screen and (max-width: 767px) {
    .nav li > form > button.logout {
      display: block;
      text-align: left;
      width: 100%;
      padding: 10px 15px; } }
  .nav li > form > button.logout:focus, .nav li > form > button.logout:hover {
    text-decoration: none; }
  .nav li > form > button.logout:focus {
    outline: none; }

.visual {
  text-align: center;
  color: #fff;
  font-family: "bikoregular", Arial, Helvetica, sans-serif;
  line-height: 1.14;
  text-shadow: 0 9px 24px rgba(0, 0, 0, 0.66);
  display: flex;
  background-size: cover;
  background: url("../../images/bg-home.jpg") no-repeat 50% 50%;
  flex-direction: column;
  position: relative; }
  .visual .visual-holder {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 210px 0 100px;
    flex-direction: column;
    font-size: 60px; }
    @media only screen and (max-width: 1024px) {
      .visual .visual-holder {
        min-height: 0;
        font-size: 42px; } }
    @media only screen and (max-width: 767px) {
      .visual .visual-holder {
        padding-top: 130px;
        padding-bottom: 60px;
        font-size: 28px; } }
  .visual .arrow-bottom {
    position: fixed;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid #fe008f;
    font-size: 0;
    background: #fff;
    bottom: 20px;
    right: 20px;
    transition: opacity .35s;
    z-index: 9999; }
    @media only screen and (max-width: 1562px) {
      .visual .arrow-bottom {
        right: 8px; } }
    @media only screen and (max-width: 767px) {
      .visual .arrow-bottom {
        bottom: 20px;
        right: 2px;
        transition: none; } }
    .visual .arrow-bottom:before {
      content: '';
      position: absolute;
      left: 50%;
      text-align: center;
      top: 50%;
      transform: translateX(-50%) translateY(0) translateZ(0) rotate(-45deg);
      border: 2px solid #fe008f;
      border-width: 0 0 3px 3px;
      overflow: hidden;
      width: 24px;
      height: 24px;
      clip: rect(1px, 27px, 27px, 0);
      margin: -16px 0 0;
      backface-visibility: hidden;
      transition: transform .35s; }
      @media only screen and (max-width: 1024px) {
        .visual .arrow-bottom:before {
          animation: none; } }
    .visual .arrow-bottom:hover {
      opacity: .8; }
    .visual .arrow-bottom.toTop:before {
      transform: translateX(-50%) translateY(0) translateZ(0) rotate(135deg);
      margin-top: -7px; }
  .visual .container {
    max-width: 1010px;
    margin: 0 auto; }
  .visual p {
    margin: 0 0 17px; }
    @media only screen and (max-width: 767px) {
      .visual p {
        margin: 0 0 10px; } }
  .visual .note {
    display: block;
    font-size: 30px; }
    @media only screen and (max-width: 1024px) {
      .visual .note {
        font-size: 21px; } }
    @media only screen and (max-width: 767px) {
      .visual .note {
        font-size: 14px; } }

.visual-text {
  padding: 0 0 32px; }

.fade-effect {
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  opacity: 0; }
  .fade-effect.anim-1 {
    animation-delay: 0.5s; }
  .fade-effect.anim-2 {
    animation-delay: 3.5s; }
  .fade-effect.anim-3 {
    animation-delay: 6.5s; }
  .fade-effect.anim-4 {
    animation-delay: 7s; }

.content-row {
  padding: 116px 0;
  font-family: "cabinregular", Arial, Helvetica, sans-serif;
  color: #000; }
  @media only screen and (max-width: 1024px) {
    .content-row {
      padding: 30px 0; } }
  .content-row.center-aligned {
    text-align: center; }

.intro {
  text-align: center;
  font-family: "bikoregular", Arial, Helvetica, sans-serif;
  color: #0f7e00;
  font-size: 30px;
  line-height: 1.08; }
  @media only screen and (max-width: 1024px) {
    .intro {
      font-size: 20px; } }
  @media only screen and (max-width: 767px) {
    .intro {
      font-size: 17px; } }

.intro-text {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 0 20px; }
  .intro-text p {
    margin: 0 0 29px; }

.text-attention {
  display: block;
  color: #000;
  font-family: "jenna_sueregular", Arial, Helvetica, sans-serif;
  font-size: 100px;
  line-height: 1; }
  @media only screen and (max-width: 1024px) {
    .text-attention {
      font-size: 80px; } }
  @media only screen and (max-width: 767px) {
    .text-attention {
      font-size: 60px; } }

.item-list {
  padding: 0 0 77px;
  color: #fff;
  max-width: 1360px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap; }
  @media only screen and (max-width: 767px) {
    .item-list {
      padding: 0 0 15px; } }
  .item-list li {
    padding: 0 45px 30px;
    width: 50%; }
    .item-list li:before {
      display: none; }
    @media only screen and (max-width: 1562px) {
      .item-list li {
        padding: 0 15px 15px; } }
    @media only screen and (max-width: 767px) {
      .item-list li {
        width: 100%;
        padding: 0 0 15px;
        min-height: initial; } }
  .item-list .popup-holder {
    color: #fff;
    text-decoration: underline;
    transition: color .35s; }
    .item-list .popup-holder:hover {
      text-decoration: none; }
      .item-list .popup-holder:hover .img-popup {
        opacity: 1;
        width: auto;
        height: auto;
        cursor: default; }
        @media only screen and (max-width: 767px) {
          .item-list .popup-holder:hover .img-popup {
            display: none; } }
      @media only screen and (max-width: 767px) {
        .item-list .popup-holder:hover {
          text-decoration: underline; } }
  .item-list .item {
    background: linear-gradient(to right, #16ad68 0%, #17a654 100%);
    box-shadow: 0 9px 24px rgba(0, 0, 0, 0.33);
    border-radius: 29px;
    font-size: 25px;
    line-height: 32px;
    padding: 20px 14px;
    height: 100%;
    position: relative;
    flex-shrink: 0; }
    @media only screen and (max-width: 1562px) {
      .item-list .item {
        font-size: 21px;
        line-height: 25px; } }
    @media only screen and (max-width: 1024px) {
      .item-list .item {
        font-size: 16px;
        line-height: 20px;
        padding: 15xp 10px; } }
    @media only screen and (max-width: 480px) {
      .item-list .item {
        font-size: 14px;
        line-height: 18px;
        min-height: initial; } }
    .item-list .item p {
      margin: 0 0 -10px 0;
      color: initial;
      font-size: 20px;
      position: absolute;
      right: 19px;
      margin: 0;
      bottom: 0px;
      line-height: 1; }
      @media only screen and (max-width: 1024px) {
        .item-list .item p {
          font-size: 16px; } }
      @media only screen and (max-width: 480px) {
        .item-list .item p {
          float: none;
          font-size: 16px; } }
  .item-list .img-popup {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    transition: opacity .35s;
    z-index: 9999;
    max-width: 1000px; }
    .item-list .img-popup img {
      display: block; }

.text-holder {
  max-width: 800px;
  margin: 0 auto;
  font-size: 28px; }
  @media only screen and (max-width: 1024px) {
    .text-holder {
      font-size: 20px; } }
  @media only screen and (max-width: 767px) {
    .text-holder {
      font-size: 17px; } }
  .text-holder p {
    margin: 0; }
  .text-holder a {
    text-decoration: underline; }

.boxes {
  font-size: 20px;
  line-height: 1.35;
  font-family: "cabinbold", Arial, Helvetica, sans-serif;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 0 2px;
  color: #000;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .boxes {
      padding: 0; } }
  .boxes p {
    margin: 0; }
  @media only screen and (max-width: 1024px) {
    .boxes {
      font-size: 17px; } }
  .boxes .box {
    width: 50%;
    border: 1px solid #000;
    margin: -1px 0 0 -1px;
    background: #ecf8fe; }
    .boxes .box.blue {
      background: #ecf8fe;
      color: #000 !important; }
    .boxes .box.pink {
      background: #fdedf4;
      color: #000 !important; }
    .boxes .box.yellow {
      background: #fffde9;
      color: #000 !important; }
    .boxes .box.grey {
      background: #f3f4f4;
      color: #000 !important; }
    .boxes .box:nth-child(odd) .box-content {
      margin: 0 0 0 auto; }
    .boxes .box:nth-child(even) .box-content {
      margin: 0 auto 0 0; }
    @media only screen and (max-width: 767px) {
      .boxes .box {
        width: 100%;
        margin: -1px 0 0;
        border: none; } }
  .boxes .box-content {
    max-width: 764px;
    padding: 70px 130px 200px;
    position: relative;
    height: 100%; }
    @media only screen and (max-width: 1562px) {
      .boxes .box-content {
        padding: 50px 50px 190px; } }
    @media only screen and (max-width: 1024px) {
      .boxes .box-content {
        padding-bottom: 150px; } }
    @media only screen and (max-width: 767px) {
      .boxes .box-content {
        padding: 20px; } }
  .boxes .btn-holder {
    position: absolute;
    left: 0;
    right: 0;
    padding: 0 15px;
    bottom: 109px; }
    @media only screen and (max-width: 1024px) {
      .boxes .btn-holder {
        bottom: 69px; } }
    @media only screen and (max-width: 767px) {
      .boxes .btn-holder {
        position: static;
        padding: 15px 0 0; } }
  .boxes .note {
    font-family: "cabinsemibold", Arial, Helvetica, sans-serif;
    font-size: 18px; }
    @media only screen and (max-width: 1024px) {
      .boxes .note {
        font-size: 15px; } }
  .boxes .image {
    text-align: center;
    margin: 0 0 30px; }
    @media only screen and (max-width: 1024px) {
      .boxes .image {
        max-width: 60%;
        margin: 0 auto 15px; } }

.box-title {
  display: inline-block;
  vertical-align: top;
  background: url("../../images/text-titles.png") no-repeat;
  margin: 0 0 26px;
  font-size: 0;
  height: 52px; }
  @media only screen and (max-width: 1024px) {
    .box-title {
      background-size: 260px 228px;
      height: 40px;
      margin-bottom: 15px; } }
  .box-title.text-work {
    background-position: 0 0;
    width: 251px; }
    @media only screen and (max-width: 1024px) {
      .box-title.text-work {
        width: 192px; } }
  .box-title.text-libraries {
    background-position: 0 -72px;
    width: 322px;
    height: 83px; }
    @media only screen and (max-width: 1024px) {
      .box-title.text-libraries {
        width: 246px;
        height: 65px;
        background-position: 0 -54px; } }
  .box-title.text-space {
    background-position: 0 -175px;
    width: 341px; }
    @media only screen and (max-width: 1024px) {
      .box-title.text-space {
        background-position: 0 -133px;
        width: 260px; } }
  .box-title.text-tech {
    background-position: 0 -247px;
    width: 189px; }
    @media only screen and (max-width: 1024px) {
      .box-title.text-tech {
        background-position: 0 -188px;
        width: 145px; } }

.section-bullets {
  position: fixed;
  top: 50%;
  right: 45px;
  z-index: 9999;
  padding: 100px 0;
  transform: translateY(-50%);
  font-size: 0;
  line-height: 0;
  text-align: center;
  margin: 0; }
  @media only screen and (max-width: 1562px) {
    .section-bullets {
      right: 8px; } }
  @media only screen and (max-width: 767px) {
    .section-bullets {
      right: 2px; } }
  .section-bullets li {
    margin: 0 0 10px;
    padding: 1px;
    vertical-align: top; }
    .section-bullets li:before {
      display: none; }
    .section-bullets li:last-child {
      margin-bottom: 0; }
  .section-bullets a {
    display: block;
    width: 12px;
    height: 12px;
    position: relative;
    text-decoration: none;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden; }
    .section-bullets a:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 8px;
      height: 8px;
      background: #fff;
      border: 1px solid #fe008f;
      border-radius: 50%;
      transition: all .2s;
      margin: -4px 0 0 -4px; }
    .section-bullets a:hover, .section-bullets a.active {
      text-decoration: none; }
      .section-bullets a:hover:before, .section-bullets a.active:before {
        background: #fe008f;
        width: 12px;
        height: 12px;
        margin: -6px 0 0 -6px; }

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 9998; }
  .show-load .loading {
    display: block !important;
    background: rgba(255, 255, 255, 0.5); }

.cssload-loader {
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 62px;
  height: 62px;
  border-radius: 50%;
  perspective: 780px; }

.cssload-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 50%; }

.cssload-inner.cssload-one {
  left: 0%;
  top: 0%;
  animation: cssload-rotate-one 1.15s linear infinite;
  border-bottom: 3px solid #eb008b; }

.cssload-inner.cssload-two {
  right: 0%;
  top: 0%;
  animation: cssload-rotate-two 1.15s linear infinite;
  border-right: 3px solid #00adee; }

.cssload-inner.cssload-three {
  right: 0%;
  bottom: 0%;
  animation: cssload-rotate-three 1.15s linear infinite;
  border-top: 3px solid #faeb1e; }

.preload * {
  transition: none !important;
  animation: none !important; }

@keyframes cssload-rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg); }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg); } }

@keyframes cssload-rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg); }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg); } }

@keyframes cssload-rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg); }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg); } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(-50%) translateY(0) translateZ(0) rotate(-45deg); }
  40% {
    transform: translateX(-50%) translateY(-15px) translateZ(0) rotate(-45deg); }
  60% {
    transform: translateX(-50%) translateY(-8px) translateZ(0) rotate(-45deg); } }

.site-pros {
  height: 100%;
  background: url("../../images/bg-1.jpg") no-repeat 50% 50%;
  background-size: cover; }
  .site-pros .content-blc {
    background: rgba(236, 248, 254, 0.8); }
  .site-pros .box-title {
    width: 390px;
    min-height: 102px;
    margin-bottom: 40px;
    background: url("../../images/pros-title-big.png") 0px 0px no-repeat;
    background-size: contain; }
    @media only screen and (max-width: 1562px) {
      .site-pros .box-title {
        background-size: 70%; } }
    @media only screen and (max-width: 1024px) {
      .site-pros .box-title {
        background-size: 70%;
        width: 70%;
        margin-bottom: 30px; } }
    @media only screen and (max-width: 767px) {
      .site-pros .box-title {
        background-size: 100%;
        width: 248px;
        height: 51px; } }

.site-libraries {
  height: 100%;
  background: url("../../images/bg-2.jpg") no-repeat 50% 50%;
  background-size: cover; }
  .site-libraries .content-blc {
    background: rgba(253, 237, 244, 0.8); }
  .site-libraries .box-title {
    width: 540px;
    height: 140px;
    margin-bottom: 60px;
    background: url("../../images/lib-title-big.png") 0px 0px no-repeat;
    background-size: contain; }
    @media only screen and (max-width: 1562px) {
      .site-libraries .box-title {
        background-size: 70%; } }
    @media only screen and (max-width: 1024px) {
      .site-libraries .box-title {
        width: 70%;
        margin-bottom: 30px;
        background-size: 70%; } }
    @media only screen and (max-width: 767px) {
      .site-libraries .box-title {
        background-size: 100%;
        width: 248px;
        height: 63px; } }

.site-space {
  height: 100%;
  background: url("../../images/bg-3.jpg") no-repeat 50% 50%;
  background-size: cover; }
  .site-space .content-blc {
    background: rgba(255, 253, 233, 0.8);
    width: 60%; }
    @media screen and (max-width: 1200px) {
      .site-space .content-blc {
        width: 70%; } }
    @media only screen and (max-width: 1024px) {
      .site-space .content-blc {
        width: 100%; } }
  .site-space .box-title {
    width: 505px;
    height: 77px;
    margin-bottom: 60px;
    background: url("../../images/space-title-big.png") 0px 0px no-repeat;
    background-size: contain; }
    @media only screen and (max-width: 1562px) {
      .site-space .box-title {
        background-size: 70%; } }
    @media only screen and (max-width: 1024px) {
      .site-space .box-title {
        width: 70%;
        margin-bottom: 30px;
        background-size: 70%; } }
    @media only screen and (max-width: 767px) {
      .site-space .box-title {
        background-size: 100%;
        width: 248px;
        height: 70px; } }

.site-old-tech {
  height: 100%;
  background: url("../../images/bg-4.jpg") no-repeat 50% 50%;
  background-size: cover; }
  .site-old-tech .content-blc {
    background: rgba(243, 244, 244, 0.8); }
    .site-old-tech .content-blc .box-title {
      width: 285px;
      height: 77px;
      margin-bottom: 60px;
      background: url("../../images/old-title-big.png") 0px 0px no-repeat;
      background-size: contain; }
      @media only screen and (max-width: 1562px) {
        .site-old-tech .content-blc .box-title {
          background-size: 70%; } }
      @media only screen and (max-width: 1024px) {
        .site-old-tech .content-blc .box-title {
          width: 70%;
          margin-bottom: 30px;
          background-size: 40%; } }
      @media only screen and (max-width: 767px) {
        .site-old-tech .content-blc .box-title {
          background-size: 100%;
          width: 200px;
          height: 55px; } }

.site-about {
  background: url("../../images/bg-inner.jpg") repeat-y 50% 0%; }
  .site-about .content-blc {
    width: 100%;
    background: rgba(236, 248, 254, 0.7); }
  .site-about .title {
    text-align: center;
    color: #000;
    font-size: 48px;
    margin-bottom: 60px; }
  .site-about .subtitle {
    text-align: center; }
  .site-about .content-text {
    margin-bottom: 50px; }
    .site-about .content-text p {
      font-size: 18px;
      color: #4c4c4c;
      margin-bottom: 20px; }
    .site-about .content-text .list {
      list-style: none;
      display: flex;
      flex-wrap: wrap; }
      .site-about .content-text .list li {
        width: 25%;
        margin-bottom: 15px; }
        @media only screen and (max-width: 767px) {
          .site-about .content-text .list li {
            width: 50%; } }
        @media only screen and (max-width: 480px) {
          .site-about .content-text .list li {
            width: 100%; } }
    .site-about .content-text .inner {
      background: #ffeabc;
      padding: 40px 0 55px;
      max-width: 910px;
      border: 1px solid #fff;
      text-align: center;
      display: flex; }
      .site-about .content-text .inner.no-bg {
        background: none;
        border: 0; }
      .site-about .content-text .inner.full-width {
        max-width: 100%; }
      @media only screen and (max-width: 767px) {
        .site-about .content-text .inner {
          display: block; } }
      .site-about .content-text .inner-item {
        padding: 0 35px;
        width: 33.33%; }
        @media only screen and (max-width: 1024px) {
          .site-about .content-text .inner-item {
            padding: 0 10px; } }
        @media only screen and (max-width: 767px) {
          .site-about .content-text .inner-item {
            padding: 20px;
            width: 100%; } }
        .site-about .content-text .inner-item_title {
          font-weight: 400;
          font-family: 'Arial';
          margin-bottom: 35px;
          font-size: 14px; }

.site-contact {
  text-align: center; }
  .site-contact .info {
    display: flex;
    text-align: left;
    padding: 0 66px;
    margin-top: 30px;
    margin-bottom: 27px;
    font-size: 24px;
    line-height: 1.45; }
    @media only screen and (max-width: 1562px) {
      .site-contact .info {
        padding: 0; } }
    @media only screen and (max-width: 767px) {
      .site-contact .info {
        display: block;
        font-size: 18px; } }
    .site-contact .info-item {
      width: 50%;
      padding: 0 5px 0 10%;
      color: #000; }
      @media only screen and (max-width: 1024px) {
        .site-contact .info-item {
          padding-left: 5%; } }
      @media only screen and (max-width: 767px) {
        .site-contact .info-item {
          width: auto;
          padding: 0; } }
  .site-contact h3 {
    margin-bottom: 100px;
    color: #000; }
  .site-contact .title-box {
    color: #000;
    font-size: 24px; }
  .site-contact .content-text {
    padding: 0 150px;
    text-align: left; }
    @media only screen and (max-width: 1024px) {
      .site-contact .content-text {
        padding: 0; } }
  .site-contact .form-holder {
    text-align: left;
    font-size: 18px;
    font-weight: normal; }
    .site-contact .form-holder .help-block {
      font-size: 12px; }
  .site-contact .form-text h3 {
    margin-bottom: 15px; }

.content-blc {
  margin-top: 250px;
  margin-bottom: 340px;
  width: 42%;
  padding: 50px 52px 150px;
  border: 1px solid #000; }
  @media screen and (max-width: 1200px) {
    .content-blc {
      width: 70%; } }
  @media only screen and (max-width: 1024px) {
    .content-blc {
      width: 100%; } }
  @media only screen and (max-width: 767px) {
    .content-blc {
      padding: 30px 20px;
      margin-top: 120px;
      margin-bottom: 50px; } }
  .content-blc.left-aligned {
    font-family: "cabinbold", Arial, Helvetica, sans-serif; }
  .content-blc p {
    margin-bottom: 10px;
    color: #000;
    font-size: 24px;
    line-height: 1.5; }
    @media only screen and (max-width: 767px) {
      .content-blc p {
        font-size: 18px; } }
  .content-blc i {
    color: #000; }

.blue {
  color: #00adee !important; }

.pink {
  color: #fe008f !important; }

.yellow {
  color: #d0cc7f !important; }

.f24 {
  font-size: 24px !important; }

.intro-holder .subtitle {
  font-size: 100px;
  color: #000;
  margin: 0 0 11px; }
  @media only screen and (max-width: 1024px) {
    .intro-holder .subtitle {
      font-size: 80px; } }
  @media only screen and (max-width: 767px) {
    .intro-holder .subtitle {
      font-size: 60px;
      line-height: 1; } }

.content-blc .intro-holder p {
  margin-bottom: 40px; }
  @media only screen and (max-width: 1024px) {
    .content-blc .intro-holder p {
      margin-bottom: 20px; } }

.intro-holder h3 {
  font-size: 30px; }
  @media only screen and (max-width: 767px) {
    .intro-holder h3 {
      font-size: 21px; } }

.intro-holder .info h3 {
  margin-bottom: 0;
  color: #00adee; }

@media only screen and (max-width: 480px) {
  .g-recaptcha {
    transform: scale(0.82) translateX(-27px); } }

#auth-popup .close {
  font-size: 44px;
  line-height: 30px;
  color: #fff;
  margin-top: -33px;
  opacity: 1; }

#auth-popup .modal-body {
  padding: 0; }
  #auth-popup .modal-body ul {
    border: none; }
    #auth-popup .modal-body ul li {
      width: 50%;
      padding: 0;
      background: #ebebeb; }
      #auth-popup .modal-body ul li a {
        font-size: 24px;
        border: none;
        margin: 0;
        text-align: center;
        color: #878787;
        padding: 15px; }
      #auth-popup .modal-body ul li.active {
        background: #fff; }
        #auth-popup .modal-body ul li.active a {
          color: #000; }
      #auth-popup .modal-body ul li:before {
        display: none; }

#auth-popup .site-login {
  margin-top: 15px; }
  #auth-popup .site-login h2 {
    text-align: center; }
  #auth-popup .site-login p {
    text-align: center;
    font-size: 16px; }
    #auth-popup .site-login p.help-block {
      text-align: left;
      font-size: 12px; }

#auth-popup .form-group {
  margin: 0; }
  #auth-popup .form-group .form-control {
    box-sizing: border-box;
    height: 58px;
    border: 1px solid #888;
    border-radius: 0;
    background: #ebebeb;
    font-size: 18px; }
    #auth-popup .form-group .form-control:focus {
      outline: none;
      box-shadow: none; }
  #auth-popup .form-group.has-error .form-control {
    border-color: #a94442; }

#auth-popup .check-holder {
  display: flex;
  align-items: center; }
  #auth-popup .check-holder input[type=checkbox] {
    margin: 0;
    opacity: 0;
    display: none; }
    #auth-popup .check-holder input[type=checkbox]:checked + label:after {
      opacity: 1; }
  #auth-popup .check-holder label {
    margin-bottom: 0;
    font-size: 18px;
    position: relative;
    padding: 0 0 0 25px; }
    #auth-popup .check-holder label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 3px;
      width: 15px;
      height: 15px;
      border: 1px solid #888;
      margin: 0 10px 0 0;
      background: #ebebeb;
      z-index: 1; }
    #auth-popup .check-holder label:after {
      content: '';
      position: absolute;
      left: 3px;
      top: 7px;
      width: 9px;
      height: 5px;
      border-bottom: 2px solid #888;
      border-left: 2px solid #888;
      z-index: 2;
      opacity: 0;
      transform: rotate(-45deg); }

#auth-popup .btn-holder {
  margin-top: 30px;
  text-align: center; }
  #auth-popup .btn-holder .btn {
    border-radius: 0;
    background: #000;
    border: none; }
    #auth-popup .btn-holder .btn:hover {
      background: #404040; }

#auth-popup .link-holder {
  margin-top: 15px;
  text-align: center; }

#auth-popup .modal-footer {
  border: none; }

#auth-popup.modal.fade .modal-dialog {
  transform: translate(0, -25%);
  /* top: 50%; */
  bottom: -25%; }

#auth-popup.modal.in .modal-dialog {
  transform: translate(0, -25%);
  /* top: 50%; */
  bottom: -25%; }
  @media only screen and (max-width: 1024px) {
    #auth-popup.modal.in .modal-dialog {
      transform: translate(0, 0);
      top: 25px; } }

.info-holder {
  position: relative; }
  .info-holder input.form-control {
    padding-right: 36px; }
    @media only screen and (max-width: 1024px) {
      .info-holder input.form-control {
        padding-right: inherit; } }

@media only screen and (max-width: 1024px) {
  .tooltip-holder {
    display: none; } }

.tooltip-holder:hover .tooltip {
  opacity: 1;
  width: 100%;
  height: auto;
  padding: 15px; }

.ico-info {
  fill: #50505a;
  display: block;
  width: 22px;
  height: 22px;
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 10px; }

.tooltip {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity .35s;
  text-align: left;
  z-index: 9998;
  max-width: 400px; }
  .tooltip p {
    margin: 0; }

#auth-popup .tooltip p {
  text-align: left; }

@media only screen and (max-width: 767px) {
  .main.admin {
    height: 100%; } }

.admin-pages {
  height: 100%;
  background: url("../../images/bg-1.jpg") no-repeat 50% 50%;
  background-size: cover; }
  @media only screen and (max-width: 767px) {
    .admin-pages h1 {
      font-size: 26px; } }
  .admin-pages .content-blc {
    width: 100%;
    background: rgba(255, 255, 255, 0.92); }
    .admin-pages .content-blc.edit-blc {
      width: 50%;
      margin-left: auto;
      margin-right: auto; }
      @media only screen and (max-width: 1024px) {
        .admin-pages .content-blc.edit-blc {
          width: 100%; } }
  .admin-pages .header {
    margin-bottom: 20px; }
  .admin-pages .krajee-default.file-preview-frame {
    overflow: hidden; }

.pagination {
  width: 100%;
  display: flex;
  justify-content: center; }
  .pagination li {
    padding: 0; }
    .pagination li:before {
      display: none; }

.footer {
  background: #252525;
  color: #fff;
  padding: 44px 0; }
  @media only screen and (max-width: 767px) {
    .footer {
      text-align: center;
      padding: 20px 0; } }
  .footer p {
    margin: 0; }
  .footer a {
    color: #fff; }
  .footer .logo {
    float: left; }
    @media only screen and (max-width: 767px) {
      .footer .logo {
        float: none;
        display: inline-block;
        vertical-align: top; } }

.footer-menu.nav {
  font-size: 0;
  font-family: "bikoregular", Arial, Helvetica, sans-serif;
  margin: 45px 0 0;
  float: right; }
  @media only screen and (max-width: 767px) {
    .footer-menu.nav {
      float: none;
      margin-top: 15px; } }
  .footer-menu.nav li {
    padding: 0;
    margin: 0 0 0 70px;
    font-size: 24px;
    display: inline-block;
    vertical-align: top; }
    .footer-menu.nav li:before {
      display: none; }
    @media only screen and (max-width: 1024px) {
      .footer-menu.nav li {
        margin-left: 33px;
        font-size: 18px; } }
    @media only screen and (max-width: 767px) {
      .footer-menu.nav li {
        margin: 0 5px;
        font-size: 14px; } }
    .footer-menu.nav li.dropdown {
      display: none; }
  .footer-menu.nav a {
    display: inline-block;
    vertical-align: top;
    padding: 0; }
    .footer-menu.nav a:hover, .footer-menu.nav a:focus, .footer-menu.nav a:active {
      text-decoration: none;
      color: #00adee;
      background: none; }
  .footer-menu.nav .separator {
    font-size: 0; }
    .footer-menu.nav .separator:before {
      content: '/';
      display: inline-block;
      vertical-align: top;
      font-size: 24px;
      margin: 0 6px; }
      @media only screen and (max-width: 1024px) {
        .footer-menu.nav .separator:before {
          font-size: 18px; } }
      @media only screen and (max-width: 767px) {
        .footer-menu.nav .separator:before {
          font-size: 14px;
          margin: 0 3px; } }

@media only screen and (max-width: 767px) {
  .logo svg {
    width: 160px;
    height: 74px; } }

.logo a {
  display: block;
  transition: opacity .35s; }
  .logo a:hover {
    opacity: .6; }
