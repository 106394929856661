@font-face {
    font-family: 'bikoregular';
    src: url('../../fonts/biko_regular-webfont.woff2') format('woff2'),
         url('../../fonts/biko_regular-webfont.woff') format('woff');
}
@font-face {
    font-family: 'jenna_sueregular';
    src: url('../../fonts/jennasue-webfont.woff2') format('woff2'),
         url('../../fonts/jennasue-webfont.woff') format('woff');
}
@font-face {
    font-family: 'cabinregular';
    src: url('../../fonts/cabin-regular-webfont.woff2') format('woff2'),
         url('../../fonts/cabin-regular-webfont.woff') format('woff');
}
@font-face {
    font-family: 'cabinbold';
    src: url('../../fonts/cabin-bold-webfont.woff2') format('woff2'),
         url('../../fonts/cabin-bold-webfont.woff') format('woff');
}
@font-face {
    font-family: 'cabinsemibold';
    src: url('../../fonts/cabin-semibold-webfont.woff2') format('woff2'),
         url('../../fonts/cabin-semibold-webfont.woff') format('woff');
}
@font-face {
    font-family: 'cabinsemibold_italic';
    src: url('../../fonts/cabin-semibolditalic-webfont.woff2') format('woff2'),
         url('../../fonts/cabin-semibolditalic-webfont.woff') format('woff');
}
@font-face {
    font-family: 'cabinbold_italic';
    src: url('../../fonts/cabin-bolditalic-webfont.woff2') format('woff2'),
         url('../../fonts/cabin-bolditalic-webfont.woff') format('woff');
}
@font-face {
    font-family: 'cabinitalic';
    src: url('../../fonts/cabin-italic-webfont.woff2') format('woff2'),
         url('../../fonts/cabin-italic-webfont.woff') format('woff');
}
@font-face {
    font-family: 'open_sansbold';
    src: url('../../fonts/opensans-bold-webfont.woff2') format('woff2'),
         url('../../fonts/opensans-bold-webfont.woff') format('woff');
}
@font-face {
    font-family: 'open_sansitalic';
    src: url('../../fonts/opensans-italic-webfont.woff2') format('woff2'),
         url('../../fonts/opensans-italic-webfont.woff') format('woff');
}
@font-face {
    font-family: 'open_sansregular';
    src: url('../../fonts/opensans-regular-webfont.woff2') format('woff2'),
         url('../../fonts/opensans-regular-webfont.woff') format('woff');
}