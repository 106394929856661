* {font-size: 100.01%;}
html,
body { height: 100%; }
html {font-size: 62.5%;}
body{
	margin: 0;
	background: $bg-main;
	color: $color-main;
	font: $font-main;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	min-width: 320px;
}
a{
	color: $color-link;
	text-decoration: none;
	transition: color .3s ease-out, background .3s ease-out, border-color .3s ease-out, opacity .3s ease-out;
}
a:hover{color: $color-link-hover; }
button,
input[type="submit"] {
	transition: color .3s ease-out, background .3s ease-out, border-color .3s ease-out, opacity .3s ease-out;
}
h1 {
	margin: 0 0 15px;
	font-size: 48px;
	font-family: $font-biko;
}
h2 {
	font-size: 60px;
	font-family: $font-jenna;
}
h3 {
	font-size: 24px;
	font-family: $font-cabin-bold;
}
p {
	margin: 0 0 24px;
}
strong,
b {
	font-family: $font-cabin-bold;
	font-weight: normal;
}
em,
i {
	font-family: $font-cabin-italic;
	font-style: normal;
}
strong em,
b em,
strong i,
b i,
em b,
em strong,
i b,
i strong {
	font-weight: normal;
	font-style: normal;
	font-family: $font-cabin-bold_italic;
}

ul {
	list-style: none;
	li {
		padding: 0 0 0 20px;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 7px;
			background: #00adee;
			width: 10px;
			height: 10px;
			border-radius: 50%;
		}
	}
}


.wrapper {
	display: table;
	height: 100%;
	width: 100%;
	table-layout: fixed;
	position: relative;
	background: $bg-main;;
	@include media(mobile) {
		display: block;
		//height: auto;
	}
}

.container {
	width: 100%;
	max-width: 1562px;
	padding: 0 15px;
	margin: 0 auto;
}

/* main */
.main {
	display: table-row;
	height: 100%;
	position: relative;
	z-index: 1;
	background: #fff;
	@include media(mobile) {
		display: block;
		height: auto;
	}
}
.pending {
    color: #060;
}
.in-progress {
    color: #f60;
}
.failed {
    color: #f00;
}
.done {
    color: #096;
}
.card a{
    display: inline-block;
    border: 2px transparent solid;
}
.card a.selected{
    border-color: #5cb85c;
}
.btns {
	height: 60px;
	border-radius: 8px;
	color: #fff;
	text-align: center;
	background: $bg-btn;
	min-width: 290px;
	font-size: 24px;
	line-height: 58px;
	border-color: $bg-btn;
	font-family: $font-open_sans;
	display: inline-block;
	vertical-align: top;
	text-transform: capitalize;
	padding: 0 20px;
	@include media(tablet) {
		min-width: 0;
		font-size: 18px;
		height: 48px;
		line-height: 46px;

	}
	&:hover,
	&:focus,
	&:active {
		background: $bg-btn-hover;
		border-color: $bg-btn-hover;
		color: #fff;
		text-decoration: none;
	}
	&.with-shadow {
		box-shadow: 0 9px 24px rgba(0,0,0,0.33);
	}
}
.list-view {
	font-size: 0;
}
.list-view  .card {
	display: inline-block;
	vertical-align: middle;
	padding: 0 10px;
	float: none;
	@include media(mobile) {
		width: 50%;
	}
	@include media(mobile-small) {
		width: 100%;
		text-align: center;
	}
}
.message-holder {
	margin: 0 0 50px;
	@include media(mobile) {
		margin-bottom: 30px;
	}
}
.note-box {
	color: red;
	display: inline-block;
	vertical-align: top;
	padding: 3px 0 0;
	color: #a94442;
	p {
		margin: 0;
	}
	.required {
		color: red;
	}
}
.not-set {
    color: #c55;
    font-style: italic;
}
/* add sorting icons to gridview sort links */
a.asc:after, a.desc:after {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    padding-left: 5px;
}

a.asc:after {
    content: /*"\e113"*/ "\e151";
}

a.desc:after {
    content: /*"\e114"*/ "\e152";
}

.sort-numerical a.asc:after {
    content: "\e153";
}

.sort-numerical a.desc:after {
    content: "\e154";
}

.sort-ordinal a.asc:after {
    content: "\e155";
}

.sort-ordinal a.desc:after {
    content: "\e156";
}

.grid-view th {
    white-space: nowrap;
}

.hint-block {
    display: block;
    margin-top: 5px;
    color: #999;
}

.error-summary {
    color: #a94442;
    background: #fdf7f7;
    border-left: 3px solid #eed3d7;
    padding: 10px 20px;
    margin: 0 0 15px 0;
}
form {
	div.required {
		label {
			&.control-label {
				&:after {
					content:" * ";
					color:red;
				}
			}
		}
	}
}