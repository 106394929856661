.main{
  &.admin{
    @include media(mobile){
      height: 100%;
    }
  }
}
.admin-pages{
  height: 100%;
  background: url('../../images/bg-1.jpg') no-repeat 50% 50%;
  background-size: cover;
  h1{
    @include media(mobile){
      font-size: 26px;
    }
  }
  .content-blc{
    width: 100%;
    background: rgba(255,255,255, .92);
    &.edit-blc{
      width: 50%;
      margin-left: auto;
      margin-right: auto;
      @include media(tablet){
        width: 100%;
      }
    }
  }

  .header{
    margin-bottom: 20px;
  }
  .krajee-default.file-preview-frame{
    overflow: hidden;
  }
}

.pagination{
  width: 100%;
  display: flex;
  justify-content: center;
  li{
    padding: 0;
    &:before{
      display: none;
    }
  }
}