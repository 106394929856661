@mixin media($point) {
  @if $point == desktop {
   @media only screen and (max-width: $desktop) { @content; } 
  }
  @else if $point == tablet {
   @media only screen and (max-width: $tablet) { @content; } 
  }
  @else if $point == mobile {
   @media only screen and (max-width: $mobile) { @content; } 
  }
  @else if $point == mobile-small {
   @media only screen and (max-width: $mobile-small) { @content; } 
  }
}

@mixin triangle-left($width, $height, $bg) {
  width:0;
  height:0;
  border-style: solid;
  border-width:$height/2 $width $height/2 0;
  border-color: transparent $bg transparent transparent;
}
@mixin triangle-right($width, $height, $bg) {
  width:0;
  height:0;
  border-style: solid;
  border-width:$height/2 0 $height/2 $width;
  border-color: transparent transparent transparent $bg;
}
@mixin triangle-top($width, $height, $bg) {
  width:0;
  height:0;
  border-style: solid;
  border-width:0 $width/2 $height $width/2;
  border-color: transparent transparent $bg transparent;
}
@mixin triangle-bottom($width, $height, $bg) {
  width:0;
  height:0;
  border-style: solid;
  border-width:$height $width/2 0 $width/2;
  border-color:$bg transparent transparent transparent;
}