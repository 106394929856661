#auth-popup{
  .close{
    font-size: 44px;
    line-height: 30px;
    color: #fff;
    margin-top: -33px;
    opacity: 1;

  }

  .modal-body{
    padding: 0;
    ul{
      border: none;
      li{
        width: 50%;
        padding: 0;
        background: #ebebeb;
        a{
          font-size: 24px;
          border: none;
          margin: 0;
          text-align: center;
          color: #878787;
          padding: 15px;
        }

        &.active{
          background: #fff;
          a{
            color: #000;
          }
        }
        &:before{
          display: none;
        }
      }
    }
  }


  .site-login{
      margin-top: 15px;
    h2{
      text-align: center;
    }
    p{
      text-align: center;
      font-size: 16px;
      &.help-block{
        text-align: left;
        font-size: 12px;
      }
    }
  }

  .form-group{
    margin: 0;
    .form-control{
      box-sizing: border-box;
      height: 58px;
      border: 1px solid #888;
      border-radius: 0;
      background: #ebebeb;
      font-size: 18px;
      &:focus{
        outline: none;
        box-shadow: none;
      }
    }
    &.has-error{
      .form-control{
        border-color: #a94442;
      }
    }
  }

  .check-holder{
    display: flex;
    align-items: center;


    input[type=checkbox]{
      margin: 0;
      opacity: 0;
      display: none;
      &:checked{
        & + label{
          &:after{
            opacity: 1;
          }
        }
      }

    }
    label{
      margin-bottom: 0;
      font-size: 18px;
      //margin-left: 10px;
      position: relative;
      padding: 0 0 0 25px;
      &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 3px;
        width: 15px;
        height: 15px;
        border:1px solid #888;
        margin: 0 10px 0 0;
        background: #ebebeb;
        z-index: 1;
      }
      &:after{
        content: '';
        position: absolute;
        left: 3px;
        top: 7px;
        width: 9px;
        height: 5px;
        border-bottom: 2px solid #888;
        border-left: 2px solid #888;
        z-index: 2;
        opacity: 0;
        transform: rotate(-45deg);
      }
    }

  }

  .btn-holder{
    margin-top: 30px;
    text-align: center;
    .btn{
      border-radius: 0;
      background: #000;
      border: none;
      &:hover{
        background: lighten(#000, 25%);
      }
    }
  }


  .link-holder{
    margin-top: 15px;
    text-align: center;
  }

  .modal-footer{
    border: none;
  }
  &.modal.fade .modal-dialog{
    transform: translate(0, -25%);
    /* top: 50%; */
    bottom: -25%;
  }
  &.modal.in .modal-dialog{
    transform: translate(0, -25%);
    /* top: 50%; */
    bottom: -25%;
    @include media(tablet){
      transform: translate(0, 0);
      top: 25px;
    }

  }



  &.modal{
    //@include media(tablet){
    //  position: static;
    //}
  }
}
.info-holder {
  position: relative;
  input.form-control {
    padding-right: 36px;
    @include media(tablet) {
      padding-right: inherit;
    }
  }
}
.tooltip-holder {
  @include media(tablet) {
    display: none;
  }
  &:hover {
    .tooltip {
      opacity: 1;
      width: 100%;
      height: auto;
      padding: 15px;
    }
  }
}
.ico-info {
  fill: #50505a;
  display: block;
  width: 22px;
  height: 22px;
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 10px;
}
.tooltip {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border: 1px solid #ccc;
  background: #fff;
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity .35s;
  text-align: left;
  z-index: 9998;
  max-width: 400px;
  p {
    margin: 0;
  }
}
#auth-popup .tooltip p {
  text-align: left;
}
