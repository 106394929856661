.visual {
	text-align: center;
	color: #fff;
	font-family: $font-biko;
	line-height: 1.14;
	text-shadow: 0 9px 24px rgba(0,0,0,0.66);
	display: flex;
	background-size: cover;
	background: url('../../images/bg-home.jpg') no-repeat 50% 50%;
	flex-direction: column;
	position: relative;
	.visual-holder {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		min-height: 100vh;
		padding: 210px 0 100px;
		flex-direction: column;
		font-size: 60px;
		@include media (tablet) {
			min-height: 0;
			font-size: 42px;
		}
		@include media (mobile) {
			padding-top: 130px;
			padding-bottom: 60px;
			font-size: 28px;
		}
	}
	.arrow-bottom {
		position: fixed;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		border: 2px solid $color-pink;
		font-size: 0;
		background: #fff;
		bottom: 20px;
		right: 20px;
		transition: opacity .35s;
		z-index: 9999;
		@include media (desktop) {
			right: 8px;
		}
		@include media (mobile) {
			bottom: 20px;
			right: 2px;
			transition: none;
		}
		&:before {
			content: '';
			position: absolute;
			left: 50%;
			text-align: center;
			top: 50%;
			transform: translateX(-50%) translateY(0) translateZ(0) rotate(-45deg);
			border: 2px solid $color-pink;
			border-width: 0 0 3px 3px;
			overflow: hidden;
			width: 24px;
			height: 24px;
			clip: rect(1px, 27px, 27px, 0);
			margin: -16px 0 0;
			backface-visibility: hidden;
			transition: transform .35s;
			@include media (tablet) {
				animation: none;
			}
		}
		&:hover {
			opacity: .8;
		}
		&.toTop {
			&:before {
				transform: translateX(-50%) translateY(0) translateZ(0) rotate(135deg);
				margin-top: -7px;
			}
		}
	}
	.container {
		max-width: 1010px;
		margin: 0 auto;
	}
	p {
		margin: 0 0 17px;
		@include media (mobile) {
			margin: 0 0 10px;
		}
	}
	.note {
		display: block;
		font-size: 30px;
		@include media (tablet) {
			font-size: 21px;
		}
		@include media (mobile) {
			font-size: 14px;
		}
	}
}
.visual-text {
	padding: 0 0 32px;
}
.fade-effect {
	animation-name: fadeIn;
	animation-duration: 2s;
	animation-fill-mode: forwards;
	opacity: 0;

	&.anim-1 {
		animation-delay: 0.5s;
	}
	&.anim-2 {
		animation-delay: 3.5s;
	}
	&.anim-3 {
		animation-delay: 6.5s;
	}
	&.anim-4 {
		animation-delay: 7s;
	}
}
.content-row {
	padding: 116px 0;
	font-family: $font-cabin-regular;
	color: #000;
	@include media (tablet) {
		padding: 30px 0;
	}
	&.center-aligned {
		text-align: center;
	}
}
.intro {
	text-align: center;
	font-family: $font-biko;
	color: #0f7e00;
	font-size: 30px;
	line-height: 1.08;
	@include media (tablet) {
		font-size: 20px;
	}
	@include media (mobile) {
		font-size: 17px;
	}
}
.intro-text {
	max-width: 900px;
	margin: 0 auto;
	padding: 0 0 20px;
	p {
		margin: 0 0 29px;
	}
}
.text-attention {
	display: block;
	color: #000;
	font-family: $font-jenna;
	font-size: 100px;
	line-height: 1;
	@include media(tablet) {
		font-size: 80px;
	}
	@include media(mobile) {
		font-size: 60px;
	}
}
.item-list {
	padding: 0 0 77px;
	color: #fff;
	max-width: 1360px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	@include media(mobile) {
		padding: 0 0 15px;
	}
	li {
		padding: 0 45px 30px;
		width: 50%;

		&:before {
			display: none;
		}
		@include media(desktop) {
			padding: 0 15px 15px;
		}
		@include media(mobile) {
			width: 100%;
			padding: 0 0 15px;
			min-height: initial;
		}



	}
	.popup-holder {
		color: #fff;
		text-decoration: underline;
		transition: color .35s;
		&:hover {
			text-decoration: none;
			.img-popup {
				opacity: 1;
				width: auto;
				height: auto;
				cursor: default;
				@include media(mobile) {
					display: none;
				}
			}
			@include media(mobile) {
				text-decoration: underline;
			}
		}
	}
	.item {
		background: linear-gradient(to right, #16ad68 0%,#17a654 100%);
		box-shadow: 0 9px 24px rgba(0,0,0,0.33);
		border-radius: 29px;
		font-size: 25px;
		line-height: 32px;
		padding: 20px 14px;
		height: 100%;
		position: relative;
		//min-height: 82px;
		//display: flex;
		//flex-wrap: wrap;
		//align-items: center;
		//justify-content: center;
		flex-shrink: 0;
		@include media(desktop) {
			font-size: 21px;
			line-height: 25px;
		}
		@include media(tablet) {
			font-size: 16px;
			line-height: 20px;
			padding: 15xp 10px;
		}
		@include media(mobile-small) {
			font-size: 14px;
			line-height: 18px;
			min-height: initial;
		}
		p{
			margin: 0 0 -10px 0;
			color: initial;
			font-size: 20px;
			position: absolute;
			right: 19px;
			margin: 0;
			bottom: 0px;
			line-height: 1;
			@include media(tablet) {
				font-size: 16px;
			}
			@include media(mobile-small) {
				float: none;
				font-size: 16px;
			}
		}
	}
	.img-popup {
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		opacity: 0;
		width: 0;
		height: 0;
		overflow: hidden;
		transition: opacity .35s;
		z-index: 9999;
		max-width: 1000px;
		img {
			display: block;
		}
	}
}
.text-holder {
	max-width: 800px;
	margin: 0 auto;
	font-size: 28px;
	@include media(tablet) {
		font-size: 20px;
	}
	@include media(mobile) {
		font-size: 17px;
	}
	p {
		margin: 0;
	}
	a {
		text-decoration: underline;
	}
}
.boxes {
	font-size: 20px;
	line-height: 1.35;
	font-family: $font-cabin-bold;
	display: flex;
	flex-wrap: wrap;
	padding: 0 0 0 2px;
	color: #000;
	text-align: center;
	@include media(mobile) {
		padding: 0;
	}
	p {
		margin: 0;
	}
	@include media(tablet) {
		font-size: 17px;
	}
	.box {
		width: 50%;
		border: 1px solid $border-color;
		margin: -1px 0 0 -1px;
		background: #ecf8fe;
		&.blue {background: #ecf8fe; color: #000!important;}
		&.pink {background: #fdedf4; color: #000!important;}
		&.yellow {background: #fffde9; color: #000!important;}
		&.grey {background: #f3f4f4; color: #000!important;}
		&:nth-child(odd) {
			.box-content {
				margin: 0 0 0 auto;
			}
		}
		&:nth-child(even) {
			.box-content {
				margin: 0 auto 0 0;
			}
		}
		@include media(mobile) {
			width: 100%;
			margin: -1px 0 0;
			border: none;
		}
	}
	.box-content {
		max-width: 764px;
		padding: 70px 130px 200px;
		position: relative;
		height: 100%;
		@include media(desktop) {
			padding: 50px 50px 190px;
		}
		@include media(tablet) {
			padding-bottom: 150px;
		}
		@include media(mobile) {
			padding: 20px;
		}
	}
	.btn-holder {
		position: absolute;
		left: 0;
		right: 0;
		padding: 0 15px;
		bottom: 109px;
		@include media(tablet) {
			bottom: 69px;
		}
		@include media(mobile) {
			position: static;
			padding: 15px 0 0;
		}
	}
	.note {
		font-family: $font-cabin;
		font-size: 18px;
		@include media(tablet) {
			font-size: 15px;
		}
	}
	.image {
		text-align: center;
		margin: 0 0 30px;
		@include media(tablet) {
			max-width: 60%;
			margin: 0 auto 15px;
		}
	}
}

.box-title {
	display: inline-block;
	vertical-align: top;
	background: url('../../images/text-titles.png') no-repeat;
	margin: 0 0 26px;
	font-size: 0;
	height: 52px;
	@include media(tablet) {
		background-size: 260px 228px;
		height: 40px;
		margin-bottom: 15px;
	}
	&.text-work {
		background-position: 0 0;
		width: 251px;
		@include media(tablet) {
			width: 192px;
		}
	}
	&.text-libraries {
		background-position: 0 -72px;
		width: 322px;
		height: 83px;
		@include media(tablet) {
			width: 246px;
			height: 65px;
			background-position: 0 -54px;
		}
	}
	&.text-space {
		background-position: 0 -175px;
		width: 341px;
		@include media(tablet) {
			background-position: 0 -133px;
			width: 260px;
		}
	}
	&.text-tech {
		background-position: 0 -247px;
		width: 189px;
		@include media(tablet) {
			background-position: 0 -188px;
			width: 145px;
		}
	}
}
.section-bullets {
	position: fixed;
	top: 50%;
	right: 45px;
	z-index: 9999;
	padding: 100px 0;
	transform: translateY(-50%);
	font-size: 0;
	line-height: 0;
	text-align: center;
	margin: 0;
	@include media (desktop) {
		right: 8px;
	}
	@include media (mobile) {
		right: 2px;
	}
	li {
		margin: 0 0 10px;
		padding: 1px;
		vertical-align: top;
		&:before {
			display: none;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	a {
		display: block;
		width: 12px;
		height: 12px;
		position: relative;
		text-decoration: none;
		transform: translate3d(0,0,0);
		backface-visibility: hidden;
		&:before {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			width: 8px;
			height: 8px;
			background: #fff;
			border:  1px solid #fe008f;
			border-radius: 50%;
			transition: all .2s;
			margin: -4px 0 0 -4px;
		}
		&:hover,
		&.active {
			text-decoration: none;
			&:before {
				background: #fe008f;
				width: 12px;
				height: 12px;
				margin: -6px 0 0 -6px;
			}
		}
	}
}

.loading{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	z-index: 9998;

	.show-load &{
		display: block!important;
		background: rgba(255,255,255, .5);
	}
}

.cssload-loader {
	position: fixed;
	z-index: 9999;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	width: 62px;
	height: 62px;
	border-radius: 50%;
	perspective: 780px;
}

.cssload-inner {
	position: absolute;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	border-radius: 50%;
}

.cssload-inner.cssload-one {
	left: 0%;
	top: 0%;
	animation: cssload-rotate-one 1.15s linear infinite;
	border-bottom: 3px solid #eb008b;
}

.cssload-inner.cssload-two {
	right: 0%;
	top: 0%;
	animation: cssload-rotate-two 1.15s linear infinite;
	border-right: 3px solid #00adee;
}

.cssload-inner.cssload-three {
	right: 0%;
	bottom: 0%;
	animation: cssload-rotate-three 1.15s linear infinite;
	border-top: 3px solid #faeb1e;
}
.preload * {
	transition: none !important;
	animation: none !important;
}

@keyframes cssload-rotate-one {
	0% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}

@keyframes cssload-rotate-two {
	0% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}

@keyframes cssload-rotate-three {
	0% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
		transform: translateX(-50%) translateY(0) translateZ(0) rotate(-45deg);
	}
	40% {
		transform: translateX(-50%) translateY(-15px) translateZ(0) rotate(-45deg);
	}
	60% {
		transform: translateX(-50%) translateY(-8px) translateZ(0) rotate(-45deg);
	}
}