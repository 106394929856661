.site-pros{
  height: 100%;
  background: url('../../images/bg-1.jpg') no-repeat 50% 50%;
  background-size: cover;
  .content-blc{
    background: rgba(#ecf8fe, .8);
  }
  .box-title{
    width: 390px;
    min-height: 102px;
    margin-bottom: 40px;
    background: url('../../images/pros-title-big.png') 0px 0px no-repeat;
    background-size: contain;
    @include media(desktop) {
      background-size: 70%;
    }
    @include media(tablet) {
      background-size: 70%;
      width: 70%;
      margin-bottom: 30px;
    }
    @include media(mobile) {
      background-size: 100%;
      width: 248px;
      height: 51px;
    }
  }
}

.site-libraries{
  height: 100%;
  background: url('../../images/bg-2.jpg') no-repeat 50% 50%;
  background-size: cover;
  .content-blc{
    background: rgba(#fdedf4, .8);
  }
  .box-title{
    width: 540px;
    height: 140px;
    margin-bottom: 60px;
    background: url('../../images/lib-title-big.png') 0px 0px no-repeat;
    background-size: contain;
    @include media(desktop) {
      background-size: 70%;
    }
    @include media(tablet) {
      width: 70%;
      margin-bottom: 30px;
      background-size: 70%;
    }
    @include media(mobile) {
      background-size: 100%;
      width: 248px;
      height: 63px;
    }
  }
}

.site-space{
  height: 100%;
  background: url('../../images/bg-3.jpg') no-repeat 50% 50%;
  background-size: cover;
  .content-blc{
    background: rgba(#fffde9, .8);
    width: 60%;
    @media screen and (max-width: 1200px){
      width: 70%;
    }
    @include media(tablet) {
      width: 100%;
    }
  }
  .box-title{
    width: 505px;
    height: 77px;
    margin-bottom: 60px;
    background: url('../../images/space-title-big.png') 0px 0px no-repeat;
    background-size: contain;
    @include media(desktop) {
      background-size: 70%;
    }
    @include media(tablet) {
      width: 70%;
      margin-bottom: 30px;
      background-size: 70%;
    }
    @include media(mobile) {
      background-size: 100%;
      width: 248px;
      height: 70px;
    }
  }
}

.site-old-tech{
  height: 100%;
  background: url('../../images/bg-4.jpg') no-repeat 50% 50%;
  background-size: cover;
  .content-blc{
    background: rgba(#f3f4f4, .8);
    .box-title{
      width: 285px;
      height: 77px;
      margin-bottom: 60px;
      background: url('../../images/old-title-big.png') 0px 0px no-repeat;
      background-size: contain;
      @include media(desktop) {
        background-size: 70%;
      }
      @include media(tablet) {
        width: 70%;
        margin-bottom: 30px;
        background-size: 40%;
      }
      @include media(mobile) {
        background-size: 100%;
        width: 200px;
        height: 55px;
      }
    }
  }

}

.site-about{
  //height: 100%;
  background: url('../../images/bg-inner.jpg') repeat-y 50% 0%;
  //background-size: cover;
  .content-blc{
    width: 100%;
    background: rgba(#ecf8fe, .7);
  }
  .title{
    text-align: center;
    color: #000;
    font-size: 48px;
    margin-bottom: 60px;
  }

  .subtitle{
    text-align: center;
  }

  .content-text{
    margin-bottom: 50px;
    .title-blc{

    }
    p{
      font-size: 18px;
      color: $color-main;
      margin-bottom: 20px;
    }

    .list{
      list-style: none;
      display: flex;
      flex-wrap: wrap;

      li{
        width: 25%;
        margin-bottom: 15px;
        @include media(mobile) {
          width: 50%;
        }
        @include media(mobile-small) {
          width: 100%;
        }
      }
    }

    .inner{
      background: #ffeabc;
      padding: 40px 0 55px;
      max-width: 910px;
      border: 1px solid #fff;
      text-align: center;
      display: flex;
      &.no-bg {
        background: none;
        border: 0;
      }
      &.full-width {
        max-width: 100%;
      }
      @include media(mobile) {
        display: block;
      }
      &-item{
        padding: 0 35px;
        width: 33.33%;
        @include media(tablet) {
          padding: 0 10px;
        }
        @include media(mobile) {
          padding: 20px;
          width: 100%;
        }
        &_title{
          font-weight: 400;
          font-family: 'Arial';
          margin-bottom: 35px;
          font-size: 14px;
        }
      }
    }
  }
}

.site-contact{
  text-align: center;

  .info{
    display: flex;
    text-align: left;
    padding: 0 66px;
    margin-top: 30px;
    margin-bottom: 27px;
    font-size: 24px;
    line-height: 1.45;
    @include media(desktop) {
      padding: 0;
    }
    @include media(mobile) {
      display: block;
      font-size: 18px;
    }

    &-item{
      width: 50%;
      padding: 0 5px 0 10%;
      color: #000;
      @include media(tablet) {
        padding-left: 5%;
      } 
      @include media(mobile) {
        width: auto;
        padding: 0;
      }
    }
  }

  h3{
    margin-bottom: 100px;
    color: #000;
  }
  .title-box{
    color: #000;
    font-size: 24px;
  }

  .content-text{
    padding: 0 150px;
    text-align: left;
    @include media(tablet) {
      padding: 0;
    }
  }
  .form-holder {
    text-align: left;
    font-size: 18px;
    font-weight: normal;
    .help-block {
      font-size: 12px;
    }
  }
  .form-text {
    h3 {
      margin-bottom: 15px;
    }
  }
}

.content-blc{
  margin-top: 250px;
  margin-bottom: 340px;
  width: 42%;
  padding: 50px 52px 150px;

  border: 1px solid #000;
  @media screen and (max-width: 1200px){
    width: 70%;
  }
  @include media(tablet) {
    width: 100%;
  }
  @include media(mobile) {
    padding: 30px 20px;
    margin-top: 120px;
    margin-bottom: 50px;
  }
  &.left-aligned {
    font-family: "cabinbold", Arial, Helvetica, sans-serif;
  }

  p{
    margin-bottom: 10px;
    color: #000;
    font-size: 24px;
    line-height: 1.5;
    @include media(mobile) {
      font-size: 18px;
    }
  }
  i{
    color: #000;
  }
}

.blue{
  color: $color-link!important;
}
.pink{
  color: $color-pink!important;
}
.yellow{
  color: $color-yellow!important;
}

.f24{
  font-size: 24px!important;
}
.intro-holder {
  .subtitle {
    font-size:  100px;
    color: #000;
    margin: 0 0 11px;
    @include media(tablet) {
      font-size:  80px;
    }
    @include media(mobile) {
      font-size: 60px;
      line-height: 1;
    }
  }
  p {
    .content-blc & {
      margin-bottom: 40px;
       @include media(tablet) {
        margin-bottom: 20px;
       }
    }
  }
  h3 {
    font-size: 30px;
    @include media(mobile) {
      font-size: 21px;
    }
  }
  .info {
    h3 {
      margin-bottom: 0;
      color: #00adee;
    }
  }
}
.g-recaptcha {
  @include media(mobile-small) {
    transform: scale(0.82) translateX(-27px);
  } 
}