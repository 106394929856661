.navbar-inverse {
	background: #fff;
	border: 0;
	padding: 12px 0;
	@include media(mobile) {
		padding: 5px 0;
	}
	.navbar-nav {
		font-family: $font-biko;
		font-size: 18px;
		margin: 30px 0 0;
		transition: opacity .2s;
		color: #000;

		@include media(mobile) {
			margin: 0;
			padding: 15px;
			color: #fff;
			font-size: 17px;
		}
		li {
			padding: 0;
			margin: 0 0 0 33px;
			@include media(mobile) {
				margin: 0 0 10px;
			}
			&:last-child {
				
				@include media(mobile) {
					margin-bottom: 0;
				}
				&.dropdown{
					span{
						vertical-align: middle;
					}
					& > a{
						@include media(mobile) {
							display: none;
						}
					}
					.dropdown-menu{
						padding: 20px;
						@include media(mobile) {
							display: block;
							position: static;
							background: transparent;
							border: none;
							padding: 0;
						}
						li{
							margin: 0 0 10px 0;
							&.dropdown-header{
								font-size: 14px;
							}
							@include media(mobile) {
								font-size: 17px;
							}
							&:last-child{
								margin: 0;
							}
							a{
								&:hover{
									text-decoration: underline;
								}
							}
						}
					}
					&.open{
						a{
							background: transparent;
							color: #000;
						}
					}
				}
			}
			&:before {
				display: none;
			}
			&.active {
				a {
					color: $bg-btn;
					background: none;
				}
			}
			a {
				color: #000;
				padding: 0;
				display: inline-block;
				vertical-align: top;
				@include media(mobile) {
					color: #fff;
					display: inline-block;
					vertical-align: top;
				}
				&:hover,
				&:focus,
				&:active {
					color: $bg-btn;
					background: none;
				}
			}
		}
		.separator {
			margin: 0 3px;
		}
	}
	.navbar-toggle {
		background: $bg-dark;
		margin: 20px 0 0;
		cursor: pointer;
		transition: background .35s, border-color .35s;
		@include media(mobile) {
			margin-top: 15px;
		}
		&:hover {
			background: $bg-btn;
			border-color: $bg-btn;
		}
	}
	.navbar-collapse {
		@include media(mobile) {
			background: $bg-dark;
			max-height: none;
			margin: 0;
			overflow: visible;
			position: absolute;
			left: 0;
			right: 0;
			top: 100%;
		}
		.navbar-nav {
			@include media(mobile) {
				opacity: 0;
			}
		}
		&.in {
			.navbar-nav {
				opacity: 1;
			}
		}
	}
	.navbar-header {
		margin: 0;
	}
}
.header {
	.navbar-inverse {
		.navbar-brand {
			background: url('../../images/logo.svg') no-repeat;
			font-size: 0;
			line-height: 0;
			padding: 0;
			width: 215px;
			height: 78px;
			margin: 0;
			transition: opacity .35s;
			&:hover {
				opacity: 0.6;
			}
			@include media(mobile) {
				width: 180px;
				height: 65px;
				background-size: 180px 65px;
			}
		}
	}
}

.modal-open{
	.header .navbar-fixed-top{
		padding-right: 17px;
		@include media(mobile) {
			padding-right: 0;
		}
	}
}

body.iosBugFixCaret.modal-open { position: fixed; width: 100%; }
.nav {
	li {
		> form {
			> button.logout {
				padding: 15px;
				border: none;
				@include media(mobile) {
					display:block;
					text-align: left;
					width: 100%;
					padding: 10px 15px;
				}
				&:focus,
				&:hover {
					text-decoration: none;
				}
				&:focus {
					outline: none;
				}
			}
		}
	}
}