/* Global reset
*******************************************************************************/
html, body, div, a, object, iframe, p, blockquote, pre,
img, dl, dt, dd, ol, ul, li, h1, h2, h3, h4, h5, h6,
fieldset, form, legend, textarea, button, input, select, figure{
	margin: 0;
	padding: 0;
	outline: 0;
	box-sizing: border-box;
}
/* =HTML5 display definitions
*******************************************************************************/
header,
footer,
article,
section,
nav,
figure,
aside,
main{ display: block; }
audio,
canvas,
video{ display: inline-block; }
audio {
	&:not([controls]) {
		display: none;
		height: 0;
	}
}
mark { background: none; }
[hidden]{ display: none; }
svg {
	&:not(:root){ overflow: hidden; }
}
/* Tags reset
*******************************************************************************/
blockquote,
q{ quotes:none; }
blockquote,
q {
	&:before,
	&:after {
		content: '';
		content: none;
	}
}
table{
	border-collapse: collapse;
	border-spacing: 0;
}
hr{
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}
ul,
ol{
	margin: 0 0 15px;
	list-style-position: inside;
}
ul,
ol {
	ul,
	ol {
		padding: 0 0 0 15px;
	}
}
a,
label {
	&:hover,
	&:active,
	&:focus { outline: none; }
}
img{
	border: 0;
	vertical-align: top;
	display: inline-block;
	max-width: 100%;
	height: auto;
}
/* Normalizing form elements
*******************************************************************************/
form,
fieldset{ border: none; }
textarea{
	overflow: auto;
	min-height: 30px;
	max-height: 400px;
	resize: vertical;
	-webkit-border-radius: 0;
	-webkit-appearance: none;
}
input {
	&[type="checkbox"],
	&[type="radio"] {
		padding: 0;
		vertical-align: middle;
	}
}
button{
	cursor: pointer;
	&[disabled] { cursor: default; }
}
button,
input[type="submit"] {
	&::-moz-focus-inner{
		border: 0;
		padding: 0;
	}
}
input {
	&[type="button"],
	&[type="reset"],
	&[type="submit"] { cursor: pointer; }
	&[disabled] { cursor: default; }
	&[type="image"] {
		padding: 0;
		border: none;
	}
	&[type="submit"],
	&[type="button"],
	&[type="text"],
	&[type="password"],
	&[type="file"],
	&[type="search"],
	&[type="email"],
	&[type="tel"] { -webkit-appearance: none; }
	&[type="search"] {
		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration { display: none; }
	}
	&::-ms-clear{
		display: none;
		width : 0;
		height: 0;
	}
	&::-ms-reveal {
		display: none;
		width : 0;
		height: 0;
	}
	&[type="number"] {
		-moz-appearance: textfield;
		-webkit-appearance: none;
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="submit"],
input[type="text"]{ -webkit-border-radius: 0; }

/* Normalizing Tags
*******************************************************************************/
small{ font-size: 85%; }
strong,
th{ font-weight: bold; }
td,
td img{ vertical-align: top; }
sub,
sup{
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
sup{ top: -0.5em; }
sub{ bottom: -0.25em; }
h1, h2, h3, h4, h5, h6 { margin: 0 0 18px; }
p { margin: 0 0 18px; }

/* =Minimal base appearance
*******************************************************************************/
html{height: 100%;}
input,
textarea,
select{
	font: 100% $font-main;
	vertical-align: middle;
}
input,
textarea {
	&::-webkit-input-placeholder{color: $color-main;}
	&:-ms-input-placeholder{color: $color-main;}
	&:-moz-placeholder,
	&::-moz-placeholder{
		color: $color-main;
		opacity: 1;
	}
	&:focus::-webkit-input-placeholder { color: transparent; }
	&:focus::-moz-placeholder          { color: transparent; }
	&:focus:-ms-input-placeholder      { color: transparent; }
}
/* =Useful classes
*******************************************************************************/
.clearfix:before,
.clearfix:after{
	content:'';
	display:table;
}
.clearfix:after{clear:both;}
.alignleft,
.alignright,
.aligncenter{
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    height: auto;
}
.alignleft,
.alignright,
.aligncenter {
    float: left;
    margin: 0 15px 15px 0;
    @include media(tablet){
    	float: none;
        display: block;
        margin: 0 auto 15px;
    }
}
.alignright {
    float: right;
    margin: 0 0 15px 15px;
}
.aligncenter {
	float: none;
    display: block;
    margin: 0 auto 15px;
    text-align: center;
}
/* End reset
*******************************************************************************/